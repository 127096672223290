import Button from 'react-bootstrap/Button';
import React from "react";
import './PrimaryComponent.scss'
import fireMeme from '../images/fireMeme.png'
import calendar from '../images/calendar.png'
import cert from '../images/certificate.png'
import arrow from '../images/arrow.gif'
import gif from '../images/gif.gif'
import op1 from '../images/Opiniones/op1.PNG'
import op2 from '../images/Opiniones/op2.PNG'
import op3 from '../images/Opiniones/op3.PNG'
import op4 from '../images/Opiniones/op4.PNG'
import phone from '../images/phone.png'
import ig from '../images/instagram.png'
import fb from '../images/facebook.png'
import './Responsive.scss'

export default function PrimaryComponent() {


    return (
        <div className='total-container'>
            <div className="container-1">
                <div className='call-to-action-1'>
                    <h4>Accedé a nuestro campus virtual y realizá la nivelación</h4>
                    <Button target="_blank" className='button-1' href='https://ogilmore.net/moodle/'>Ver ahora</Button>
                </div>
                <h1>Comunicate en inglés</h1>
                <h3>100% ONLINE</h3>
                <Button target="_blank" href='https://api.whatsapp.com/send/?phone=541127124495&text=Hola%21+Me+interesar%C3%ADa+recibir+m%C3%A1s+informaci%C3%B3n+sobre+los+cursos+de+ingl%C3%A9s+online.&type=phone_number&app_absent=0' className='call-to-action-2'>¡Inscribite ya!</Button>
            </div>
            <div className='container-2'>
                <p>Perdé el miedo de hablar en inglés</p>
                {/* <img>Del campus virtual???</img> Puede ser un carrousel */}
                <div className='row-container' >
                    <div className='gif-container'>
                        <img src={gif} />
                    </div>
                    <div className='container-list'>
                        <p>Disfruta de un portal incluido en tu curso con exámenes certificados sin costo adicional. Además, contás con clases grupales e individuales para reforzar tus conocimientos, aprender nuevas formas de comunicarte en inglés y de practicar tu speaking con otros compañeros y profesores.</p>
                        <ul>
                            <li>Clases online en vivo</li>
                            <li>Aula virtual disponible las 24hrs</li>
                            <li>Tutorías de consulta</li>
                        </ul>
                        <h2>First Class <img src={arrow} alt='not found' className='image-arrow' /> Free trial!</h2>
                    </div>
                </div>
                {/* <div className='container-button'>
                    <Button className='call-to-action-3'>Más información</Button>
                </div> */}
            </div>
            <div className='container-3'>
                <h3 className='cont-3-p'> Si te sentis así:</h3>
                <img src={fireMeme} alt='not found' className='fireMeme' />
                <h3 className='cont-3-p'>¡No te preocupes! <br /> Te ayudamos a organizarte para estudiar de forma eficaz, efectiva y humana.</h3>
                <div className='sub-container-3' >
                    <div className='highlight'>
                        <div>
                            <img src={calendar} alt='not found' />
                            <h5>Calendario organizado y flexible</h5>
                        </div>
                        <p>Te ayudamos a organizar tus horarios de la mejor manera posible para que puedas <strong>#ComunicarteEnInglés</strong></p>
                    </div>
                    <div className='highlight'>
                        <div>
                            <img src={cert} alt='not found' />
                            <h5>Certificados y reconocimientos</h5>
                        </div>
                    </div>
                </div>
                <Button target="_blank" className='button-info' href='https://api.whatsapp.com/send/?phone=541127124495&text=Hola%21+Me+interesar%C3%ADa+recibir+m%C3%A1s+informaci%C3%B3n+sobre+los+cursos+de+ingl%C3%A9s+online.&type=phone_number&app_absent=0'>Contactate con nosotros</Button>
            </div>
            <div className='container-4'>
                <h2>Opiniones</h2>
                <div className='scrolling-op'>
                    <img src={op1} alt='not found' />
                    <img src={op2} alt='not found' />
                    <img src={op3} alt='not found' />
                    <img src={op4} alt='not found' />
                </div>
                <Button target="_blank" href='https://www.google.com/search?q=la+cultural+inglesa+argentina+burzaco&oq=la+cultura&aqs=chrome.1.69i57j69i59j69i60.1808j0j4&sourceid=chrome&ie=UTF-8#lrd=0x95bcd4a110bb323d:0xefbb3aa6b6e77cdd,1,,,' className='call-to-action-4'>Otras opiniones</Button>
            </div>

            <div className='container-5'>
                <div className='text-5'>
                    <h2>También podés encontrarnos en:</h2>
                    <div>
                        <Button target="_blank" className='button-5' href='https://www.instagram.com/la_cultural_inglesa/'><img src={ig} alt='not found' /></Button>
                        <Button target="_blank" className='button-5' href='https://www.facebook.com/Laculturalinglesa/'><img src={fb} alt='not found' /></Button>
                    </div>
                </div>
                <img className='phone-img' src={phone} alt='not found' />
            </div>
        </div>
    )
}