import './App.css';
import NavBar from './Componentes/NavBar';
import PrimaryComponent from './Componentes/PrimaryComponent';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <PrimaryComponent/>
    </div>
  );
}

export default App;
