import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import './NavBar.scss';
import logo from '../images/small-logo.png'

export default function NavBar() {
 
    return (
        <div>
            <Navbar className='navbar-container' bg="light" variant="light" expand="lg">
                <Container>
                    <img src={logo} alt="not found"/>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link target="_blank" href="https://inglesogilmore.wixsite.com/lacultural/inscripcion">Study online</Nav.Link>
                            <Nav.Link target="_blank" href="https://ogilmore.net/moodle/">Campus virtual</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}